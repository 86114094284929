import React from 'react';
import styled, { keyframes } from 'styled-components';
import hutchinson from '../assets/images/hutchinson.png';
import gestamp from '../assets/images/gestamp.png';
import bosch from '../assets/images/bosch.png';
import amtrol from '../assets/images/amtrol.jpg';
import purem from '../assets/images/purem.jpg';
import ficosa from '../assets/images/ficosa.jpg';
import borgwarner from '../assets/images/borgwarner.jpg';

import layoutStyle from '../styles/layout';
import { contentHeaderStyles } from '../styles/text';
import { media } from '../styles/constants';
import { padding } from '../styles/layout';

const rotate = keyframes`
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
`;

const PartnerSection = styled.div`
  ${layoutStyle};
  ${contentHeaderStyles};

  h2 {
    text-align: center;
  }

  box-sizing: border-box;
  overflow: hidden;
  padding: 64px 0;
  background: white;

  @media (min-width: ${media.bigDesktop}px) {
    max-width: 100%;
    padding: ${padding.desktop};
  }
`;

const LogosSlider = styled.div`
  overflow-x: hidden;
  position: relative;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 15%;
    height: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
    content: '';
    z-index: 2;
  }

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    width: 15%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
    content: '';
    z-index: 2;
  }
`;

const Overflow = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  overflow: hidden;
`;

const LogosWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  animation: ${rotate} 30s linear infinite;
`;

const LogoItem = styled.div`
  margin-left: 1rem;
  padding: 1rem;
  aspect-ratio: 30/9;
  display: grid;
  place-items: center;
  border-radius: 1rem;

  img {
    width: 12rem;
    margin-inline: 2rem;
  }
`;

function MilkRunLogos() {
  return (
    <PartnerSection>
      <h2>Customers</h2>
      <LogosSlider>
        <Overflow>
          <LogosWrapper>
            <LogoItem>
              <img src={hutchinson} />
            </LogoItem>
            <LogoItem>
              <img src={gestamp} />
            </LogoItem>
            <LogoItem>
              <img src={borgwarner} />
            </LogoItem>
            <LogoItem>
              <img src={bosch} />
            </LogoItem>
            <LogoItem>
              <img src={amtrol} />
            </LogoItem>
            <LogoItem>
              <img src={purem} />
            </LogoItem>
            <LogoItem>
              <img src={ficosa} />
            </LogoItem>
          </LogosWrapper>
          <LogosWrapper>
            <LogoItem>
              <img src={hutchinson} />
            </LogoItem>
            <LogoItem>
              <img src={gestamp} />
            </LogoItem>
            <LogoItem>
              <img src={borgwarner} />
            </LogoItem>
            <LogoItem>
              <img src={bosch} />
            </LogoItem>
            <LogoItem>
              <img src={amtrol} />
            </LogoItem>
            <LogoItem>
              <img src={purem} />
            </LogoItem>
            <LogoItem>
              <img src={ficosa} />
            </LogoItem>
          </LogosWrapper>
          <LogosWrapper>
            <LogoItem>
              <img src={hutchinson} />
            </LogoItem>
            <LogoItem>
              <img src={gestamp} />
            </LogoItem>
            <LogoItem>
              <img src={borgwarner} />
            </LogoItem>
            <LogoItem>
              <img src={bosch} />
            </LogoItem>
            <LogoItem>
              <img src={amtrol} />
            </LogoItem>
            <LogoItem>
              <img src={purem} />
            </LogoItem>
            <LogoItem>
              <img src={ficosa} />
            </LogoItem>
          </LogosWrapper>
        </Overflow>
      </LogosSlider>
    </PartnerSection>
  );
}

export default MilkRunLogos;
