import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import layoutStyle from '../styles/layout';
import { contentHeaderStyles } from '../styles/text';
import { media, colors } from '../styles/constants';

const BackgroundContainer = styled.div`
  background-color: ${(props) => props.backgroundColor};

  > div {
    display: flex;
    flex-direction: column;

    ${layoutStyle};
    ${contentHeaderStyles};

    h2 {
      text-align: center;
    }
  }
`;

const IconListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
`;

const IconFragment = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 0 1rem;
  justify-items: center;
  align-items: center;
  text-align: center;
  flex: 1 0 100%;
  margin: 0 auto;

  @media (min-width: ${media.phoneLandscape}px) {
    flex: 0 1 50%;
  }

  @media (min-width: ${media.tabletPortrait}px) {
    flex: 1 0 25%;
  }

  h3 {
    color: ${colors.blue};
    height: ${(props) => (props.fixedHeight ? '2rem' : 'auto')};
  }

  &:not(:last-child) {
    padding-bottom: 3rem;
  }

  @media (min-width: ${media.tabletPortrait}px) {
    &:not(:last-child) {
      padding-bottom: 0;
    }
  }

  svg {
    margin-bottom: 1rem;
    width: 3rem;
    height: 3rem;
  }

  > div {
    padding-bottom: 1rem;
    display: flex;
  }
`;

const IconTitle = styled.div`
  h3 {
    font-weight: bold;
    font-size: 22px;
  }
`;

function IconList({
  title,
  list,
  backgroundColor = colors.background,
  fixedHeight = false
}) {
  return (
    <BackgroundContainer backgroundColor={backgroundColor}>
      <div>
        <h2>{title}</h2>
        <IconListContainer>
          {list.map(({ Icon, title, text }) => (
            <IconFragment key={title} fixedHeight={fixedHeight}>
              <Icon />
              <IconTitle>
                <h3>{title}</h3>
              </IconTitle>
              <p>{text}</p>
            </IconFragment>
          ))}
        </IconListContainer>
      </div>
    </BackgroundContainer>
  );
}

IconList.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array.isRequired,
  backgroundColor: PropTypes.string,
  fixedHeight: PropTypes.bool
};

export default IconList;
