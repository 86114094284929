import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/headers/milkRun';
import IconList from '../components/iconList';
import HowItWorks from '../components/howItWorks';
import ContactForm from '../components/contactForm';

import MeasurePerformanceIcon from '../assets/svg/Measure_Performance.svg';
import IdentifyBottlenecksIcons from '../assets/svg/Identify_Bottlenecks.svg';
import RoutePredictionIcon from '../assets/svg/Route_Prediction.svg';
import MilkRunAnalyticsIcon from '../assets/svg/Milkrun_Analytics.svg';

import { colors, media } from '../styles/constants';
import layoutStyle from '../styles/layout';
import MilkRunLogos from '../components/milkRunLogos';

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column-reverse;

  ${layoutStyle}

  @media (min-width: ${media.tabletPortrait}px) {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  hr {
    border: solid 1px ${colors.blue};
    border-radius: 5px;
    opacity: 0.5;
    max-width: 40rem;
    width: 100%;
    margin: 2rem 0;

    @media (min-width: ${media.tabletPortrait}px) {
      display: none;
    }
  }

  > div:first-child {
    flex: 1 0 100%;
    width: 100%;

    @media (min-width: ${media.tabletPortrait}px) {
      margin-right: 4rem;
      flex: 1 0 65%;
    }
  }

  > div:last-child {
    flex: 1 0 100%;

    @media (min-width: ${media.tabletPortrait}px) {
      display: flex;
      flex-direction: column;
      flex: 0 1 45%;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    h3 {
      line-height: 1.2;
      color: ${colors.blue};
      font-size: 1.5rem;
      padding-top: 0.5rem;
    }

    ul {
      padding-top: 0.5rem;
      list-style: none;

      svg {
        width: 1rem;
        margin-right: 0.5rem;
        margin-bottom: -0.25rem;
      }

      li {
        padding-bottom: 0.25rem;
      }
    }

    span {
      font-size: 1.25rem;
    }
  }
`;

function MilkRunPage({ location: { pathname } }) {
  const data = useStaticQuery(graphql`
    query {
      beacon: file(relativePath: { eq: "beacon_1.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      tracker: file(relativePath: { eq: "tracker.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      dashboard: file(relativePath: { eq: "dashboard.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      milkrunKit: file(relativePath: { eq: "milkrun-kit.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO title="Tugger Trains" pathname={pathname} />
      <Layout Header={<Header />}>
        <IconList
          title="Our Solution Enables You To"
          list={[
            {
              Icon: MeasurePerformanceIcon,
              title: 'Measure Performance',
              text: 'Record all tugger trains to compare performance over time'
            },
            {
              Icon: IdentifyBottlenecksIcons,
              title: 'Identify Bottlenecks',
              text: 'Pinpoint efficiency bottlenecks that cost you productivity'
            },
            {
              Icon: RoutePredictionIcon,
              title: 'Route Prediction',
              text: 'Leverage real-time data to estimate arrival times'
            },
            {
              Icon: MilkRunAnalyticsIcon,
              title: 'Tugger Train Analytics',
              text:
                'Gain insight into routes, delivery times, and shifts performance'
            }
          ]}
        />
        <HowItWorks
          ivt={true}
          list={[
            {
              title: 'Battery powered Beacons are placed on checkpoints',
              bullets: [
                'Small and low profile that ensures a simple installation without disturbing the environment',
                '3 years battery life-time combined with easy battery replacement',
                'Proprietary RF + Magnetic protocols enable reliable communication in demanding industrial environments'
              ],
              image: data.beacon.childImageSharp.fluid
            },
            {
              title:
                'Location Trackers are installed on tugger trains and use existing Wi-Fi infrastructure',
              bullets: [
                'Compatible with tugger train battery connectors for plug-and-play installation',
                'Onboard battery and memory to preserve data during Wifi and power failure events',
                'Compact and rugged enclosure to withstand demanding industrial environments'
              ],
              image: data.tracker.childImageSharp.fluid
            },
            {
              title:
                'Cloud Dashboard enables real-time status, event-driven warnings, and route analysis',
              bullets: [
                'Displays real-time position and tugger train route data',
                'Multiple accounts with different privileges',
                'API available for client integration'
              ],
              image: data.dashboard.childImageSharp.fluid
            }
          ]}
        />
        <MilkRunLogos />
        <FormContainer>
          <ContactForm
            title="Interested in a demo? Leave us a
          message"
            page="Tugger Train Tracking"
          />
          <hr />
        </FormContainer>
      </Layout>
    </>
  );
}

MilkRunPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default MilkRunPage;
