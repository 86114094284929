import React from 'react';
import styled from 'styled-components';

import Button from '../button';

import layoutStyle from '../../styles/layout';
import { colors, media } from '../../styles/constants';
import Particles, { ParticlesContainer } from './particles';

import milkRunGif from '../../assets/milkRun_450.gif';

const MilkRunHeaderContainer = styled.div`
  margin-top: 3.75rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledParticlesContainer = styled(ParticlesContainer)`
  .particles canvas {
    opacity: 0.5;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  transform: translateZ(1px);

  ${layoutStyle}

  @media (min-width: ${media.tabletPortrait}px) {
    flex-direction: row;
    text-align: left;
  }

  h1 {
    padding-bottom: 1rem;

    @media (min-width: ${media.tabletPortrait}px) {
      padding-bottom: 1.75rem;
    }
  }

  p {
      max-width: 55ch;

      :not(:last-of-type) {
        padding-bottom: 1rem;

        @media (min-width: ${media.tabletPortrait}px) {
          padding-bottom: 1.75rem;
        }
      }
    }

  > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${colors.lightGrey};

    @media (min-width: ${media.tabletPortrait}px) {
      align-items: normal;
      width: 55%;
    }

    p {
      max-width: 55ch;

        padding-bottom: 1rem;

        @media (min-width: ${media.tabletPortrait}px) {
          padding-bottom: 1.75rem;
        }
      }
    }

    p:nth-child(2) {
      @media (max-width: ${media.tabletPortrait}px) {
        display: none;
      }
    }
  }

  > div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-top: 2rem;
      max-width: 90%;
    }

    @media (min-width: ${media.tabletPortrait}px) {
      width: 50%;

      img {
        margin-right: -6rem;
      }
    }
  }
`;

function MilkRunHeader() {
  return (
    <MilkRunHeaderContainer>
      <Particles Container={StyledParticlesContainer}>
        <TitleContainer>
          <div>
            <h1>Tugger Train Tracking</h1>
            <p>
              The Tugger Train Tracking system enables companies to monitor the
              performance of tugger trains in industrial facilities. By
              leveraging real-time data, the system predicts routes and
              estimates the time of arrival at every station.
            </p>
            <p>
              Including also a Dashboard that transforms the collected data into
              insightful analytics such as average waiting and traveling time in
              each checkpoint, bottleneck identification, route performance, and
              much more.{' '}
            </p>
            <Button
              secondary
              onClick={() => {
                const element = document.getElementById('content');
                if (element) {
                  element.scrollIntoView({
                    behavior: 'smooth'
                  });
                }
              }}
            >
              Learn More
            </Button>
          </div>
          <div>
            <img
              src={milkRunGif}
              alt="Visual representation of the Tugger Train Tracking System"
            />
          </div>
        </TitleContainer>
      </Particles>
    </MilkRunHeaderContainer>
  );
}

export default MilkRunHeader;
