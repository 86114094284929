import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import layoutStyle from '../styles/layout';
import { media, colors } from '../styles/constants';

import Check from '../assets/svg/check.svg';

const BackgroundContainer = styled.div`
  background-color: ${(props) => props.backgroundColor};

  > div {
    display: flex;
    flex-direction: column;

    h2 {
      text-align: center;
    }

    ${layoutStyle}
  }
  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    margin-bottom: 5vh;
  }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const HowItWorksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
`;

const BulletsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: ${media.phonePortrait}px) {
    padding-left: 1rem;
    margin-left: 3rem;
    border-left: solid 2px ${colors.blue};
  }

  h3 {
    color: ${colors.blue};
    font-size: 1.375rem;
    padding-bottom: 1rem;
    text-align: center;

    @media (min-width: ${media.phonePortrait}px) {
      text-align: left;
    }
  }

  &:before {
    content: '${(props) => props.index + 1}';
    position: absolute;
    font-size: 3rem;
    top: 0;
    left: -2.75rem;
    color: ${colors.blue};
    display: none;

    @media (min-width: ${media.phonePortrait}px) {
      display: inherit;
    }
  }

  ul {
    list-style: none;

    li {
      display: flex;
      padding-bottom: 0.75rem;
    }

    svg {
      width: 1rem;
      margin-right: 0.5rem;
      margin-bottom: -0.25rem;
    }
  }
`;

const ListFragment = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;

  ${BulletsContainer} {
    flex: 1 1 100%;
    padding-bottom: 2rem;
  }

  .gatsby-image-wrapper {
    width: 65%;
    max-width: 600px;
    margin: 0 auto;
  }

  @media (min-width: ${media.tabletPortrait}px) {
    align-items: center;
    flex-direction: row;
    max-height: 420px;

    ${BulletsContainer} {
      flex: 1 0 55%;
      padding-right: 2rem;
      padding-bottom: 0;
    }

    .gatsby-image-wrapper {
      flex: 0 1 45%;
    }

    &:nth-child(2) {
      flex-direction: row-reverse;
    }
  }

  :last-child {
    .gatsby-image-wrapper picture {
      max-width: 90%;
      max-height: 90%;
    }
  }

  :first-child ${BulletsContainer}:before {
    left: -2rem;
    color: ${colors.blue};
  }
`;

function HowItWorks({
  title = 'How it Works',
  ivt = false,
  list,
  backgroundColor = colors.white
}) {
  return (
    <BackgroundContainer backgroundColor={backgroundColor}>
      <div>
        <h2>{title}</h2>

        {ivt && (
          <div className="video-container">
            <iframe
              src="https://www.youtube-nocookie.com/embed/QYgjxDfq55A?&rel=0&cc_load_policy=1&color=white&mute=1&autoplay=1&loop=1&playlist=QYgjxDfq55A"
              title="Azitek Tugger Trains"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
              allowFullScreen
            ></iframe>
          </div>
        )}

        <HowItWorksContainer>
          {list.map(({ title, text, bullets, image }, index) => (
            <ListFragment key={title}>
              <BulletsContainer index={index}>
                <h3>{title}</h3>
                {text && <p>{text}</p>}
                {bullets && (
                  <ul>
                    {bullets.map((bullet, index) => (
                      <li key={index}>
                        <Check />
                        <span key={index}>{bullet}</span>
                      </li>
                    ))}
                  </ul>
                )}
              </BulletsContainer>
              <Img fluid={image} imgStyle={{ height: 'auto' }} />
            </ListFragment>
          ))}
        </HowItWorksContainer>
      </div>
    </BackgroundContainer>
  );
}

HowItWorks.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array.isRequired,
  ivt: PropTypes.bool,
  backgroundColor: PropTypes.string
};

export default HowItWorks;
